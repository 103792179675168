<app-no-records *ngIf="totalRecords==0 && !indexModel.Search else ShowRecord" [data]="noRecordData"
  (onClick)="onAddUpdateLookup('')">
  <button #AddBtnHdn (click)="selectedRecord=''; ViewMode='Add'" data-bs-toggle="offcanvas" href="#PageSidebar"
    class="d-none page-header-action-items" data-title="Add New Product"> <i
      class="fa-regular fa-plus"></i></button>

</app-no-records>
<ng-template #ShowRecord>
  <!-- Page Header Start-->
  <div class="page-header">
    <div class="page-header-title">
      {{pageName.SubName | titlecase}}
    </div>
    <div class="page-header-action">
      <!-- <button (click)="onAddUpdateLookup('')" class="page-header-action-items" data-title="Add"> <i
          class="fa-regular fa-plus"></i></button> -->
      <a href="javascript:void(0)" (click)="selectedRecord=''; ViewMode='Add'" data-bs-toggle="offcanvas"
        href="#PageSidebar" class="page-header-action-items" data-title="Add New Product"> <i
          class="fa-regular fa-plus"></i></a>
      <div class="page-header-action-search">
        <button type="button" (click)="onSearch()" class="btn-filter-search"><i
            class="fa-light fa-magnifying-glass"></i></button>
        <input type="search" (keydown.backspace)="onClear()" (keydown.delete)="onClear()" (keydown.enter)="onSearch()"
          class="form-control" placeholder="Search..." [(ngModel)]="indexModel.Search" />
      </div>
    </div>
  </div>
  <!-- Page Header End-->

  <!-- Main Page Content Start -->
  <div class="table-responsive table-shadow">

    <table class="custom-mat-table" mat-table [dataSource]="dataSource" matSort role="grid"
      (matSortChange)="sortData($event)">

      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{(indexModel.Page-1)*indexModel.PageSize +(i+1)}}</td>
      </ng-container>


      <ng-container matColumnDef="ImagePath">
        <th mat-header-cell *matHeaderCellDef>Image</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <div *ngIf="element.ImagePath" class="table-list-img d-flex align-items-center justify-content-center">
            <a [href]="element.ImagePath" [attr.data-lightbox]="'Gulmohar-Jaipur'">
              <img [src]="element.ImagePath" ViewImage [defaultSrc]="'assets/images/no-img.png'">
            </a>
          </div>
        </td>
      </ng-container>


      <ng-container [matColumnDef]="column!.Value" *ngFor="let column of ViewdisplayedColumns">

        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column!.Text}} </th>
        <td mat-cell *matCellDef="let element"> {{element[column!.Value]}} </td>
      </ng-container>

      <ng-container matColumnDef="IsActive">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status </th>
        <td mat-cell *matCellDef="let element" class="table-action-btn">
          <a (click)="OnActiveStatus(element!.Id)" data-title="Update Active Status">
            <i *ngIf="element!.IsActive" class="fas fa-check-circle text-success"></i>
            <i *ngIf="!element!.IsActive" class="fas fa-ban text-danger"></i>
          </a>

        </td>
      </ng-container>

      <ng-container matColumnDef="Action">
        <th width="120" mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let row" class="table-action-btn">
          <a href="javascript:void(0)" class="table-action-btn-edit" (click)="selectedRecord=row.Id; ViewMode='Edit'"
            data-title="Edit" data-bs-toggle="offcanvas" href="#PageSidebar" data-title="Edit"><i
              class="fa-light fa-pen"></i>
          </a>

          <a class="table-action-btn-delete" (click)="updateDeleteStatus(row.Id)" data-title="Delete"> <i
              class="fa-light fa-trash-can"></i> </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div class="no-record-found text-center" *ngIf="totalRecords==0">
      <img src="assets/images/record-not-found.svg" alt="" />
      <p class="mb-0"> Sorry, No Record Found </p>
    </div>


  </div>
  <ul class="pagination" *ngIf="indexModel.PageSize<totalRecords">
    <mat-paginator #paginator [length]="totalRecords" [pageSize]="indexModel.PageSize" (page)="onPaginateChange($event)"
      [pageIndex]="(indexModel.Page-1)" showFirstLastButtons *ngIf="totalRecords!=0">
    </mat-paginator>
  </ul>
  <!-- Main Page Content End -->
</ng-template>

<div class="offcanvas offcanvas-end"
[ngClass]="(typeData?.IsInfo ||typeData?.IsSeofield ||typeData?.IsBtoBinfo ||typeData?.IsBtoBseofield)? 'pagesidebar-medium':'pagesidebar-mini'"
tabindex="-1" id="PageSidebar" data-bs-keyboard="false">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title"><span [ngSwitch]="ViewMode">
        <p *ngSwitchCase="'Add'" class="m-0">
          Add New {{pageName.SubName}}
        </p>
        <p *ngSwitchCase="'Edit'" class="m-0">
          Edit {{pageName.SubName}}
        </p>
        <p *ngSwitchDefault class="m-0">
          {{pageName.SubName}} Detail
        </p>
      </span></h5>
    <div class="d-flex align-items-center">
      <button *ngIf=" ViewMode!='Add'" (click)="changeViewMode()" class="page-header-action-items"
        data-title="{{ViewMode}}">
        <i [ngClass]="{'fa-light fa-eye':ViewMode=='Edit' ,'fa-light fa-pen':ViewMode=='Detail' }"></i>
      </button>
      <a href="javascript:void(0)" class="offcanvas-close"  #btnClose data-bs-dismiss="offcanvas"> <i class="fa-light fa-xmark"></i> </a>
    </div>
  </div>
  <div class="offcanvas-body pt-3 offcanvas-body-fix-scroll">
    <app-sub-lookup-add-edit [mode]="ViewMode" [ngClass]="{'disabled':  ViewMode=='Detail'}" [lookupId]="id" [typeData]="typeData"
      (OnClose)="closeDrawer()" (OnSave)="reloadData($event)" [Id]="selectedRecord"></app-sub-lookup-add-edit>


  </div>
</div>
