<app-no-records *ngIf="totalRecords==0 && (!indexModel.Search || indexModel.Search=='') else ShowRecord"
  [data]="noRecordData"></app-no-records>
<ng-template #ShowRecord>

  <!-- Page Header Start-->
  <div class="page-header">
    <div class="page-header-title">
      Users
    </div>
    <div class="page-header-action">
      <button routerLink="../add" class="page-header-action-items" data-title="Add New User"> <i
          class="fa-regular fa-plus"></i></button>
      <div class="page-header-action-search">
        <button type="button" (click)="onSearch()" class="btn-filter-search"><i class="fa-light fa-magnifying-glass"></i></button>
        <input type="search" (keydown.backspace)="onClear()" (keydown.delete)="onClear()" (keydown.enter)="onSearch()"
          class="form-control" placeholder="Search..." [(ngModel)]="indexModel.Search"/>
      </div>
    </div>
  </div>
  <!-- Page Header End-->

  <!-- Main Page Content Start -->
  <div class="table-responsive table-shadow">

    <table class="custom-mat-table" mat-table [dataSource]="dataSource" matSort
       role="grid"
      (matSortChange)="sortData($event)">

      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{(indexModel.Page-1)*indexModel.PageSize +(i+1)}}</td>
      </ng-container>

      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{element.FirstName ??'' +' '+element.LastName??''}}

        </td>
      </ng-container>

      <ng-container matColumnDef="ProfilePhoto">
        <th mat-header-cell *matHeaderCellDef>Image</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <div class="table-list-img d-flex align-items-center justify-content-center">
            <a [href]="element.ProfilePhoto" [attr.data-lightbox]="'Gulmohar-Jaipur'">
              <img src="{{element.ProfilePhoto}}" ViewImage [defaultSrc]="'assets/images/no-img.png'">
            </a>
          </div>
        </td>
      </ng-container>


      <ng-container [matColumnDef]="column!.Value" *ngFor="let column of ViewdisplayedColumns">

        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{column!.Text}} </th>
        <td mat-cell *matCellDef="let element"> {{element[column!.Value]??'N/A'}} </td>
      </ng-container>

      <ng-container matColumnDef="IsActive">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status </th>
        <td mat-cell *matCellDef="let element" class="table-action-btn">
          <a (click)="OnActiveStatus(element!.UserId)" data-title="Update Active Status">
            <i *ngIf="element!.IsActive" class="fas fa-check-circle text-success"></i>
            <i *ngIf="!element!.IsActive" class="fas fa-ban text-danger"></i>
          </a>

        </td>
      </ng-container>

      <ng-container matColumnDef="Action">
        <th width="120" mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let row" class="table-action-btn">
          <a href="javascript:void(0)" class="table-action-btn-view" routerLink="../detail/{{row.UserId}}"
            data-title="View Detail"><i class="fa-light fa-eye"></i>
          </a>
          <a href="javascript:void(0)" class="table-action-btn-edit" routerLink="../edit/{{row.UserId}}"
            data-title="Edit"><i class="fa-light fa-pen"></i>
          </a>
          <a class="table-action-btn-delete" (click)="updateDeleteStatus(row.UserId)" data-title="Delete">
            <i class="fa-light fa-trash-can"></i>
          </a>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="no-record-found text-center" *ngIf="totalRecords==0">
      <img src="assets/images/record-not-found.svg" alt="" />
      <p class="mb-0"> Sorry, No Record Found </p>
    </div>

  </div>

   <ul class="pagination" *ngIf="indexModel.PageSize<totalRecords">


    <mat-paginator #paginator [length]="totalRecords" [pageSize]="indexModel.PageSize" (page)="onPaginateChange($event)" [pageIndex]="(indexModel.Page-1)"
      showFirstLastButtons *ngIf="totalRecords!=0">
    </mat-paginator>
  </ul>

  <!-- Main Page Content End -->

</ng-template>
