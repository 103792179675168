<!-- Page Header Start-->
<!-- <div class="page-header">
  <div class="page-header-title">
    Product Detail
  </div>
  <div class="page-header-action">
    <button routerLink="../../edit/{{model.Id}}" class="page-header-action-items" data-title="Edit"> <i
        class="fa-light fa-pen"></i></button>
    <button (click)="backToPrevious()" class="page-header-action-items" data-title="Back to list"> <i
        class="fa-light fa-arrow-left"></i> </button>
  </div>
</div> -->
<!-- Page Header End-->


<div class="content-box" *ngIf="model?.Id">

  <div class="row product-details">
    <div class="col-lg-4">
      <div class="product-detail-img-tab">
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade text-center show active" id="PD-0">
            <img [src]="model!.ImagePath" ViewImage [defaultSrc]="'assets/images/no-img.png'" alt="">

          </div>
          <div class="tab-pane fade text-center" id="PD-{{i+1}}" *ngFor="let itm of model.Files; let i=index">
            <img [src]="itm!.FilePath" ViewImage [defaultSrc]="'assets/images/no-img.png'" alt="">
          </div>
        </div>

        <ul class="nav nav-tabs border-0 mt-2" id="myTab">
          <li class="nav-item">
            <a class="nav-link active" data-bs-toggle="tab" href="#PD-0">
              <img [src]="model!.ImagePath" ViewImage [defaultSrc]="'assets/images/no-img.png'" alt="">
            </a>
          </li>
          <li class="nav-item" *ngFor="let itm of model.Files; let i=index">
            <a class="nav-link" data-bs-toggle="tab" href="#PD-{{i+1}}">
              <img [src]="itm!.ThumbnailPath" ViewImage [defaultSrc]="'assets/images/no-img.png'" alt="">
            </a>
          </li>
        </ul>

      </div>
    </div>
    <div class="col-lg-8 mt-4 mt-lg-0">
      <div class="product-details-right-info">
        <h2>{{this.model.Name | titlecase}}</h2>
        <div class="d-flex align-items-center product-detail-price">
          <h3 class="m-0"><span>B2C Price</span> ₹ {{model.SellingPrice}} </h3>
          <h5 class="mb-0 mx-2 " *ngIf="model.Discount>0"><s>₹ {{model.Price}}</s>
          </h5>
          <p class="m-0 text-danger" *ngIf="model.Discount>0">{{model.Discount}}% off</p>
        </div>
        <div class="d-flex align-items-center product-detail-price">
          <h3 class="m-0"><span>B2B Price</span> ₹ {{model.BtoBSellingPrice}} </h3>
          <h5 class="mb-0 mx-2 " *ngIf="model.BtoBDiscount>0"><s>₹ {{model.BtoBPrice}}</s>
          </h5>
          <p class="m-0 text-danger" *ngIf="model.BtoBDiscount>0">{{model.BtoBDiscount}}% off</p>
        </div>
            <ul class="gen-details-card">
              <li *ngIf="model?.Brand">
                <strong> Brand: </strong>
                <small>{{model.Brand | titlecase}}</small>
              </li>
              <li *ngIf="model?.TypeName">
                <strong> Type: </strong>
                <small>{{model.TypeName=='All'?'B2C and B to B':model.TypeName | titlecase}}</small>
              </li>
              <li *ngIf="model?.Category">
                <strong> Category: </strong>
                <small>{{model.Category | titlecase}}</small>
              </li>
              <li *ngIf="model?.SubCategory">
                <strong> Sub Category: </strong>
                <small>{{model.SubCategory | titlecase}}</small>
              </li>
              <li *ngIf="model?.UniqueId">
                <strong> SKU ID: </strong>
                <small>{{model.UniqueId | titlecase}}</small>
              </li>
              <li *ngIf="model?.Occasion">
                <strong> Occasion: </strong>
                <small>{{model.Occasion | titlecase}}</small>
              </li>
              <li *ngIf="model?.Fabric">
                <strong> Fabric: </strong>
                <small>{{model.Fabric | titlecase}}</small>
              </li>
              <li *ngIf="model?.Length">
                <strong> Length: </strong>
                <small>{{model?.Length | titlecase}}</small>
              </li>
              <li *ngIf="model?.Color">
                <strong> Color: </strong>
                <small>{{model.Color | titlecase}}</small>
              </li>
              <li *ngIf="model?.Pattern">
                <strong> Pattern: </strong>
                <small>{{model.Pattern | titlecase}}</small>
              </li>
              <li *ngIf="model?.CaptionTag">
                <strong> Tag/Label: </strong>
                <small>{{model.CaptionTag | titlecase}}</small>
              </li>
              <!-- <tr *ngIf="model?.ShippingCharge">
                <td><strong> Shipping Charge: </strong></td>
                <td>₹ {{model.ShippingCharge }}</td>
              </tr> -->
            </ul>
        <div class="mt-2" *ngIf="model?.Desc">
          <strong class="mb-1 d-block"> Description: </strong>
          <p class="m-0" [innerHTML]="model?.Desc"></p>
        </div>
        <div class="mt-3" *ngIf="model?.Summary">
          <strong class="mb-1 d-block"> Additional Information: </strong>
          <p class="m-0" [innerHTML]="model?.Summary"></p>
        </div>

        <div class="mt-3" *ngIf="model?.MetaTitle">
          <strong class="mb-1 d-block"> Meta Title: </strong>
          <p class="m-0" [innerHTML]="model?.MetaTitle"></p>
        </div>

        <div class="mt-3" *ngIf="model?.MetaDesc">
          <strong class="mb-1 d-block"> Meta Description: </strong>
          <p class="m-0" [innerHTML]="model?.MetaDesc"></p>
        </div>

        <div class="mt-3" *ngIf="model?.Keyword">
          <strong class="mb-1 d-block"> Keyword: </strong>
          <p class="m-0" [innerHTML]="model?.Keyword"></p>
        </div>

        <div class="mt-4" *ngIf="this.model.Stocks">
          <fieldset class="border border-radius p-2 w-100">
            <div class="d-flex mb-1 mt--25 fieldset-header">
              <legend class="w-auto text-black bg-white px-3 m-0">Stock</legend>
            </div>
            <div class="table-responsive">
              <table class="custom-mat-table table-header-light">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Type</th>
                    <th>Size</th>
                    <th>Unit Price</th>
                    <th>Selling Price</th>
                    <th>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of this.model.Stocks;let index=index">
                    <td>{{index+1}}</td>
                    <td>{{item?.IsBtoB?'B2B':'B2C'}}</td>
                    <td>{{item?.Size}}</td>
                    <td>{{item?.UnitPrice}}</td>
                    <td>{{item?.SellingPrice}}</td>
                    <td>{{item?.Quantity}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </fieldset>

        </div>


      </div>
    </div>

  </div>


</div>
