<!-- Page Header Start-->
<div class="page-header">
  <div class="page-header-title">
    All Products
  </div>
  <div class="page-header-action">
    <a href="javascript:void(0)" class="page-header-action-items" (click)="downloadExcel()" data-title="Export Excel"><i
        class="fa-light fa-file-excel"></i></a>
    <a href="javascript:void(0)" class="page-header-action-items" (click)=" showImportPopup = true"
      data-title="Import Excel"><i class="fa-light fa-upload"></i></a>
    <a href="javascript:void(0)" (click)="selectedRecord=''; ViewMode='Add'" data-bs-toggle="offcanvas"
      href="#PageSidebar" class="page-header-action-items" data-title="Add New Product"> <i
        class="fa-regular fa-plus"></i></a>
    <div class="page-filter-form">
      <div class="mini-filter-form-items">
        <span class="mini-filter-count">{{ totalRecords }}</span>
        <select class="form-select" (change)="geProductList($event)">
          <option [value]="productTypeEnum.All" [selected]="productTypeEnum.All == productType">All </option>
          <option [value]="productTypeEnum.BtoC" [selected]="productTypeEnum.BtoC == productType">B2C</option>
          <option [value]="productTypeEnum.BtoB" [selected]="productTypeEnum.BtoB == productType">B2B </option>
        </select>
      </div>
    </div>
    <div class="page-header-action-search">
      <button type="button" (click)="onSearch()" class="btn-filter-search"><i
          class="fa-light fa-magnifying-glass"></i></button>
      <input type="search" (keydown.backspace)="onClear()" (keydown.delete)="onClear()" (keydown.enter)="onSearch()"
        class="form-control" placeholder="Search..." [(ngModel)]="indexModel.Search" />
      <!-- <a href="javascript:void()" (click)="onClear()" class="btn-search-text-remove"><i
                class="bi bi-x"></i></a> -->
    </div>
  </div>
</div>
<!-- Page Header End-->


<!-- <div class="tab-btns text-center mb-2 mt-3" *ngIf="totalRecords==0 && (!indexModel.Search || indexModel.Search=='')">
  <a href="javascript:void(0)" [ngClass]="{'active':productTypeEnum.All ==this.productType}"
    (click)="geProductList(productTypeEnum.All)" class="tab-btns-items">All </a>
  <a href="javascript:void(0)" [ngClass]="{'active':productTypeEnum.BtoC ==this.productType}"
    (click)="geProductList(productTypeEnum.BtoC)" class="tab-btns-items "> B2C</a>
  <a href="javascript:void(0)" [ngClass]="{'active':productTypeEnum.BtoB ==this.productType}"
    (click)="geProductList(productTypeEnum.BtoB)" class="tab-btns-items">B2B </a>
</div> -->


<app-no-records *ngIf="totalRecords==0 && (!indexModel.Search || indexModel.Search=='') else ShowRecord"
  [data]="noRecordData" (onClick)="onAddClick()"></app-no-records>
<button #AddBtnHdn (click)="selectedRecord=''; ViewMode='Add'" data-bs-toggle="offcanvas" href="#PageSidebar"
  class="d-none page-header-action-items" data-title="Add New Product"> <i class="fa-regular fa-plus"></i></button>

<ng-template #ShowRecord>

  <!-- Main Page Content Start -->

  <!-- <div class="tab-btns mb-2 mt-3">
    <a href="javascript:void(0)" [ngClass]="{'active':productTypeEnum.All ==this.productType}"
      (click)="geProductList(productTypeEnum.All)" class="tab-btns-items">All <span *ngIf="productTypeEnum.All ==this.productType" class="tab-counting"> {{totalRecords}} </span></a>
    <a href="javascript:void(0)" [ngClass]="{'active':productTypeEnum.BtoC ==this.productType}"
      (click)="geProductList(productTypeEnum.BtoC)" class="tab-btns-items "> B2C <span class="tab-counting" *ngIf="productTypeEnum.BtoC ==this.productType"> {{totalRecords}} </span></a>
    <a href="javascript:void(0)" [ngClass]="{'active':productTypeEnum.BtoB ==this.productType}"
      (click)="geProductList(productTypeEnum.BtoB)" class="tab-btns-items">B2B <span class="tab-counting" *ngIf="productTypeEnum.BtoB ==this.productType"> {{totalRecords}} </span></a>
  </div> -->


  <div class="table-responsive table-shadow">

    <table class="custom-mat-table" mat-table [dataSource]="dataSource" matSort role="grid"
      (matSortChange)="sortData($event)">
      <ng-container matColumnDef="index">
        <th mat-header-cell *matHeaderCellDef>#</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{(indexModel.Page-1)*indexModel.PageSize +(i+1)}}</td>
      </ng-container>
      <ng-container matColumnDef="SKU">
        <th mat-header-cell *matHeaderCellDef>SKU ID</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          {{element.UniqueId??'N/A'}}</td>
      </ng-container>

      <ng-container matColumnDef="ImagePath">
        <th mat-header-cell *matHeaderCellDef>Image</th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <div class="table-list-img d-flex align-items-center justify-content-center">
            <a [href]="element.ImagePath" [attr.data-lightbox]="'Gulmohar-Jaipur'">
              <img [src]="element.ImagePath" ViewImage [defaultSrc]="'assets/images/no-img.png'">
            </a>
          </div>
        </td>
      </ng-container>


      <ng-container [matColumnDef]="column!.Value" *ngFor="let column of ViewdisplayedColumns">

        <th mat-header-cell *matHeaderCellDef mat-sort-header style="min-width: 125px;"> {{column!.Text}} </th>
        <td mat-cell *matCellDef="let element"> {{element[column!.Value]}} </td>
      </ng-container>
      <ng-container matColumnDef="Price">
        <th mat-header-cell *matHeaderCellDef style="min-width: 155px;">Price</th>
        <td mat-cell *matCellDef="let element; let i = index;">

          <div
            *ngIf="(element.Type== productTypeEnum.All || element.Type== productTypeEnum.BtoC)&&( this.productType==productTypeEnum.All|| this.productType==productTypeEnum.BtoC)"
            class="prolist-price">
            <div class="d-flex flex-wrap">
              <span *ngIf="element.Price!=element.SellingPrice" title="Selling Price">
                <strong>₹</strong> {{element.SellingPrice??'N/A'}}
              </span>
              <span *ngIf="element.Discount>0" class="prolist-discount ms-1" title="Discount">
                - {{element.Discount}}% off
              </span>
              <span class="ms-1" title=" Price">
                <strong>₹</strong> {{element.Price??'N/A'}}
              </span>
            </div>

          </div>

          <div
            *ngIf="(element.Type== productTypeEnum.All || element.Type== productTypeEnum.BtoB)&&( this.productType==productTypeEnum.All|| this.productType==productTypeEnum.BtoB)"
            class="prolist-price">
            <div class="d-flex flex-wrap">
              <span *ngIf="element.BtoBPrice!=element.BtoBSellingPrice" title="Selling Price">
                <strong>₹</strong> {{element.BtoBSellingPrice??'N/A'}}
              </span>
              <span *ngIf="element.BtoBDiscount>0" class="prolist-discount ms-1" title="Discount">
                - {{element.BtoBDiscount}}% off
              </span>
              <span class="ms-1" title="Price">
                <strong>₹</strong> {{element.BtoBPrice??'N/A'}}
              </span>
            </div>

          </div>

        </td>
      </ng-container>
      <ng-container matColumnDef="IsActive">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status </th>
        <td mat-cell *matCellDef="let element" class="table-action-btn">
          <a (click)="OnActiveStatus(element!.Id)" data-title="Update Active Status">
            <i *ngIf="element!.IsActive" class="fas fa-check-circle text-success"></i>
            <i *ngIf="!element!.IsActive" class="fas fa-ban text-danger"></i>
          </a>

        </td>
      </ng-container>

      <ng-container matColumnDef="Action">
        <th style="width:100px; min-width: 100px;" mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let row" class="table-action-btn">
          <a href="javascript:void(0)" class="table-action-btn-view" (click)="selectedRecord=row.Id;ViewMode='Detail'"
            data-title="View Detail" data-bs-toggle="offcanvas" href="#PageSidebar"><i class="fa-light fa-eye"></i>
          </a>
          <a href="javascript:void(0)" class="table-action-btn-edit" (click)="selectedRecord=row.Id; ViewMode='Edit'"
            data-title="Edit" data-bs-toggle="offcanvas" href="#PageSidebar" data-title="Edit"><i
              class="fa-light fa-pen"></i>
          </a>
          <a class="table-action-btn-delete" (click)="updateDeleteStatus(row.Id)" data-title="Delete">
            <i class="fa-light fa-trash-can"></i>
          </a>
        </td>

      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <div class="no-record-found text-center" *ngIf="totalRecords==0">
      <img src="assets/images/record-not-found.svg" alt="" />
      <p class="mb-0"> Sorry, No Record Found </p>
    </div>

  </div>

  <ul class="pagination" *ngIf="indexModel.PageSize<totalRecords">
    <mat-paginator #paginator [length]="totalRecords" [pageIndex]="(indexModel.Page-1)" [pageSize]="indexModel.PageSize"
      (page)="onPaginateChange($event)" showFirstLastButtons *ngIf="totalRecords!=0">
    </mat-paginator>
  </ul>

  <!-- Main Page Content End -->


</ng-template>

<div class="offcanvas offcanvas-end pagesidebar-large" tabindex="-1" id="PageSidebar" data-bs-keyboard="false">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title"><span [ngSwitch]="ViewMode">
        <p *ngSwitchCase="'Add'" class="m-0">
          Add New Product
        </p>
        <p *ngSwitchCase="'Edit'" class="m-0">
          Edit Product
        </p>
        <p *ngSwitchDefault class="m-0">
          Product Detail
        </p>
      </span></h5>
    <div class="d-flex align-items-center">
      <button *ngIf=" ViewMode!='Add'" (click)="changeViewMode()" class="page-header-action-items"
        data-title="{{ViewMode}}">
        <i [ngClass]="{'fa-light fa-eye':ViewMode=='Edit' ,'fa-light fa-pen':ViewMode=='Detail' }"></i>
      </button>
      <a href="javascript:void(0)" class="offcanvas-close" #btnClose data-bs-dismiss="offcanvas"> <i class="fa-light fa-xmark"></i> </a>
    </div>
  </div>
  <div class="offcanvas-body ">
    <app-product-add-edit *ngIf="ViewMode!='Detail'" [ngClass]="{'d-none':  ViewMode=='Detail'}"
      (OnClose)="closePopup()" (OnSave)="reloadData($event)" [Id]="selectedRecord"></app-product-add-edit>
    <app-product-detail *ngIf=" ViewMode=='Detail'" [refreshData]="isDataRefresh"
      [ngClass]="{'d-none': ViewMode!='Detail'}" [Id]="selectedRecord"></app-product-detail>

  </div>
</div>

<app-e-docket [IsShowEDocket]="showImportPopup" (onDownloadClick)="downloadTemplate()"
  (onCancel)="showImportPopup=false" (onSubmit)="onImport($event)"></app-e-docket>
